import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';
import './App.css';

const App = () => {
    const typingElementRef = useRef(null);

    useEffect(() => {
        const options = {
            strings: [
                "<span class='title'>Coming Soon.</span><br><span class='subtitle'>A media company you can trust.</span>"
            ],
            startDelay: 1000,
            typeSpeed: 100,
            backSpeed: 50,
            showCursor: true,
            cursorChar: '|',
            loop: false,
            onComplete: () => {
                // This is where you can handle events after complete typing
            },
        };

        const typed = new Typed(typingElementRef.current, options);

        // Approximate time to change cursor size after 'Coming Soon.'
        const estimatedTimeForTitle = 1000 + ("Coming Soon.".length * 100);
        setTimeout(() => {
            const cursorElement = document.querySelector('.typed-cursor');
            if (cursorElement) {
                cursorElement.style.fontSize = '1.5rem'; // Smaller cursor size for subtitle
            }
        }, estimatedTimeForTitle);

        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <div className="App">
            <div className="content">
                <span ref={typingElementRef}></span>
            </div>
            <footer className="footer">
                &copy; {new Date().getFullYear()} Transparent Media Company Ltd. All rights reserved.
            </footer>
        </div>
    );
};

export default App;
